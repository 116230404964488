import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderIPSC from '../../components/Header/Whitby/HeaderIPSC';
import IPSCArena6Full from '../../components/Schedule/Whitby/IPSCArena6Full';

export default function IPSCArena6() {
    const { fetchSchedulesWhitby, fetchJumbotronWhitby } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesWhitby();
                fetchJumbotronWhitby();
            }
        }, 60000)

        return () => clearInterval(interval);
    });
    
    return (
        <>
            <HeaderIPSC />
            <IPSCArena6Full />
        </>
    )
}