import React, { useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext'; // Import the context
import VenueScheduleEdit from './VenueScheduleEdit';
import DateSelector from '../DatePicker/DateSelector';
import ExportCSV from '../FileUpload/ExportCSV';
import ImportCSV from '../FileUpload/ImportCSV';
import './Schedule.css';
import RoomEdit from '../Room/RoomEdit';

export default function ScheduleEdit() {
  const { schedules, selectedDate, setSelectedDate, saveSchedules } = useContext(ScheduleContext);

  // Extract the unique venue names from the data for filtering
  const uniqueVenues = [...new Set(schedules.map(schedule => schedule.Venue))];

  const filteredSchedules = (venueName) => {
    // Convert selectedDate to EST
    const estDate = new Date(selectedDate).toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    return schedules.filter(s => s.Venue === venueName && s.Date === estDate);
  };

  const handleImportData = (importedData) => {
    console.log("Imported Data:", importedData); // Log the imported data

    // Validate and filter the imported data
    const validData = importedData.filter(item => item.Date && item.StartTime && item.EndTime && item.Venue);

    if (validData.length === 0) {
      console.error("No valid data to import.");
      alert("No valid data to import. Please check your CSV file.");
      return;
    }

    const venues = [...new Set(validData.map(item => item.Venue))];

    venues.forEach(venue => {
      const venueSchedules = validData.filter(item => item.Venue === venue);
      saveSchedules(venue, venueSchedules).catch(error => {
        console.error(`Error saving schedules for ${venue}:`, error);
        alert(`Error saving schedules for ${venue}. Please check the console for more details.`);
      });
    });

    alert("File imported successfully.");
    window.location.reload();
  };

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-3 text-center">
        <DateSelector selectedDate={selectedDate} onDateChange={setSelectedDate} />
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-auto">
          <ExportCSV data={schedules} venues={uniqueVenues} />
        </div>
        <div className="col-auto">
          <ImportCSV onImport={handleImportData} />
        </div>
      </div>
      <div className="row mt-3">
        <h1 className='kanit-regular'>Essex Arena Booking</h1>
        <VenueScheduleEdit venueName="Essex Arena (Libro)" schedules={filteredSchedules('Essex Arena (Libro)')} />
        <VenueScheduleEdit venueName="Essex Arena (Shaheen)" schedules={filteredSchedules('Essex Arena (Shaheen)')} />
        <h1 className='kanit-regular'>Essex Room Booking</h1>
        <RoomEdit venueName="Barnett Board Room" schedules={filteredSchedules('Barnett Board Room')} />
        <RoomEdit venueName="Shaheen Community Room" schedules={filteredSchedules('Shaheen Community Room')} />
      </div>
    </div>
  );
}
