import '../Header.css';

export default function HeaderIPSCTitle() {
    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark bg-gradient py-3 header-ipsc">
            <div className="container-fluid text-center">
                <span className="navbar-text display-3 display-md-2 text-white kanit-regular header-ipsc-title">
                    McKinney Centre
                </span>
            </div>
        </nav>
    );
}
