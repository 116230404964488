import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import VenueSchedule from './VenueSchedule';
import './Schedule.css';

function ScheduleList() {
  const { schedules, teams } = useContext(ScheduleContext);
  const [libroSchedules, setLibroSchedules] = useState([]);
  const [shaheenSchedules, setShaheenSchedules] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    // Create a map of team codes to team names
    const teamMapping = teams.reduce((map, team) => {
      map[team.Code] = team.Name;
      return map;
    }, {});
    setTeamMap(teamMapping);

    // Filter schedules for each venue
    const filterSchedules = (venueName) => {
      return schedules.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          // Combine the schedule date and EndTime to create a complete Date object
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    const libro = ensureFiveEntries(filterSchedules('Essex Arena (Libro)'));
    const shaheen = ensureFiveEntries(filterSchedules('Essex Arena (Shaheen)'));

    setLibroSchedules(libro);
    setShaheenSchedules(shaheen);
  }, [schedules, teams]);

  function ensureFiveEntries(schedules) {
      // Sort schedules by Date and StartTime
      schedules.sort((a, b) => {
        const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
        const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
        return dateA - dateB;
      });

      // Limit to the next 5 events
      const limitedSchedules = schedules.slice(0, 5);
    
      // Add placeholders if less than 5 events are found
      const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
      while (limitedSchedules.length < 5) {
        limitedSchedules.push(placeholder);
      }
    
      return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-3 text-center">
      <div className="row">
        <div className="col">
          <VenueSchedule venueName="LIBRO RINK" schedules={libroSchedules} teamMap={teamMap} />
        </div>
        <div className="col">
          <VenueSchedule venueName="SHAHEEN RINK" schedules={shaheenSchedules} teamMap={teamMap} />
        </div>
      </div>
    </div>
  );
}

export default ScheduleList;
