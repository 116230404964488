import React, { useState, useEffect } from 'react';
import '../Schedule.css';

function formatTime(time24) {
  if (!time24) return ''; // Return an empty string if time24 is undefined or null
  const [hour, minute] = time24.split(':');
  let hour12 = parseInt(hour, 10);
  const period = hour12 >= 12 ? 'PM' : 'AM';
  hour12 = hour12 % 12 || 12; // Convert hour to 12-hour format, 0 becomes 12
  return `${hour12}:${minute} ${period}`;
}

function combineTimes(startTime, endTime) {
  const formattedStart = formatTime(startTime);
  const formattedEnd = formatTime(endTime);
  return `${formattedStart}\n${formattedEnd}`;
}

export default function VenueSchedule({ venueName, schedules, teamMap }) {
    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
      const intervalId = setInterval(() => {
          setCurrentTime(new Date());
      }, 1000);

      return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const formattedDate = currentTime.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        timeZone: 'America/Halifax'
    });

    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZone: 'America/Halifax'
    });

    return (
      <div className="venue-schedule-container card bg-secondary bg-gradient" style={{ width: '550px' }}>
        <div className="card-header bg-secondary text-white h1 kanit-regular">
          <strong>{venueName} Schedule</strong>
          <h2>{formattedDate} | {formattedTime}</h2>
        </div>
        <div className="card-body p-0">
            <table className="table table-primary table-bordered m-0" style={{ width: '100%' }}>
            <thead>
              <tr className="h3">
                <th className="time-column">Time</th>
                <th className="team-column">Team</th>
                <th className="room-column">Room</th>
              </tr>
            </thead>
            <tbody className="h4">
              {schedules.map((schedule, index) => (
                <tr key={index}>
                  <td className="time-column text-center" style={{ whiteSpace: 'pre-wrap' }}>
                    {combineTimes(schedule.StartTime, schedule.EndTime)}
                  </td>
                  <td className="team-column text-center" style={{ whiteSpace: 'pre-wrap' }}>
                    <div>{teamMap[schedule.Team] || schedule.Team || ""}</div>
                    <div>{teamMap[schedule.Opponent] || schedule.Opponent || ""}</div>
                  </td>
                  <td className="room-column text-center" style={{ whiteSpace: 'pre-wrap' }}>
                    <div>{schedule.HomeRoom || ""}</div>
                    <div>{schedule.AwayRoom || ""}</div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

    );
  }
  