import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueSchedule from './VenueSchedule';
import '../Schedule.css';
import RoomSchedule from '../../Room/Whitby/Room';

export default function IPSCArenaFull() {
  const { schedulesWhitby, teamsWhitby } = useContext(ScheduleContext); // Use context to get schedules and teams
  const [arena1Schedules, setArena1Schedules] = useState([]);
  const [arena2Schedules, setArena2Schedules] = useState([]);
  const [arena3Schedules, setArena3Schedules] = useState([]);
  const [arena4Schedules, setArena4Schedules] = useState([]);
  const [arena5Schedules, setArena5Schedules] = useState([]);
  const [arena6Schedules, setArena6Schedules] = useState([]);
  const [whitneyHallSchedules, setWhitneyHallSchedules] = useState([]);
  const [aopBoardroomSchedules, setAopBoardroomSchedules] = useState([]);
  const [largeMezzanineSchedules, setLargeMezzanineSchedules] = useState([]);
  const [smallMezzanineSchedules, setSmallMezzanineSchedules] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    // Create a map of team codes to team names
    const teamMapping = teamsWhitby.reduce((map, team) => {
      map[team.Code] = team.Name;
      return map;
    }, {});
    setTeamMap(teamMapping);

    // Filter schedules for each venue
    const filterSchedules = (venueName) => {
      return schedulesWhitby.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          // Combine the schedule date and EndTime to create a complete Date object
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    const arena1 = ensure2Entries(filterSchedules('IPSC (1)'));
    const arena2 = ensure2Entries(filterSchedules('IPSC (2)'));
    const arena3 = ensure2Entries(filterSchedules('IPSC (3)'));
    const arena4 = ensure2Entries(filterSchedules('IPSC (4)'));
    const arena5 = ensure2Entries(filterSchedules('IPSC (5)'));
    const arena6 = ensure2Entries(filterSchedules('IPSC (6)'));
    const whitneyHall = ensureNEntries(filterSchedules('Whitney Hall'));
    const aopBoardroom = ensureNEntries(filterSchedules('AOP Boardroom'));
    const largeMezzanine = ensureNEntries(filterSchedules('Large Mezzanine Boardroom'));
    const smallMezzanine = ensureNEntries(filterSchedules('Small Mezzanine Boardroom'));

    setArena1Schedules(arena1);
    setArena2Schedules(arena2);
    setArena3Schedules(arena3);
    setArena4Schedules(arena4);
    setArena5Schedules(arena5);
    setArena6Schedules(arena6);
    
    setWhitneyHallSchedules(whitneyHall);
    setAopBoardroomSchedules(aopBoardroom);
    setLargeMezzanineSchedules(largeMezzanine);
    setSmallMezzanineSchedules(smallMezzanine);
    
  }, [schedulesWhitby, teamsWhitby]); // Rerender when schedules or teams context changes

  

  function ensureNEntries(schedules) {
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });

    // Limit to the next 5 events
    const limitedSchedules = schedules.slice(0, 1);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 1) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  function ensure2Entries(schedules) {
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });

    // Limit to the next 5 events
    const limitedSchedules = schedules.slice(0, 2);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 2) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid d-flex flex-column mt-1">
      <div className="row">
        <div className="col-4 d-flex flex-column gap-2">
          <RoomSchedule venueName="Whitney Hall" schedules={whitneyHallSchedules} />
          <RoomSchedule venueName="AOP Boardroom" schedules={aopBoardroomSchedules} />
          <RoomSchedule venueName="Large Mezzanine Boardroom" schedules={largeMezzanineSchedules} />
          <RoomSchedule venueName="Small Mezzanine Boardroom" schedules={smallMezzanineSchedules} />
        </div>
        <div className="col-4 d-flex flex-column">
          <VenueSchedule venueName="Arena 1" schedules={arena1Schedules} teamMap={teamMap} />
          <VenueSchedule venueName="Arena 3" schedules={arena3Schedules} teamMap={teamMap} />
          <VenueSchedule venueName="Arena 5" schedules={arena5Schedules} teamMap={teamMap} />
        </div>
        <div className="col-4 d-flex flex-column">
          <VenueSchedule venueName="Arena 2" schedules={arena2Schedules} teamMap={teamMap} />
          <VenueSchedule venueName="Arena 4" schedules={arena4Schedules} teamMap={teamMap} />
          <VenueSchedule venueName="Arena 6" schedules={arena6Schedules} teamMap={teamMap} />
        </div>
      </div>
    </div>
  );
}
