import React, { useEffect, useContext } from 'react';
import HeaderCentennial from '../../components/Header/Guelph/HeaderCentennial';
import CentennialFull from '../../components/Schedule/Guelph/CentennialFull';
import JumbotronCentennial from '../../components/Jumbotron/Guelph/JumbotronCentennial';
import { ScheduleContext } from '../../context/ScheduleContext';

export default function CentennialFullTemplate () {
    const { fetchSchedulesGuelph, fetchJumbotronGuelph } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesGuelph();
                fetchJumbotronGuelph();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return (
        <>
        <HeaderCentennial />
        <CentennialFull />
        <JumbotronCentennial />
        </>
    )
}