import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderWestEnd from '../../components/Header/Guelph/HeaderWestEnd';
import JumbotronWestEnd from '../../components/Jumbotron/Guelph/JumbotronWestEnd';
import WestEndFull2 from '../../components/Schedule/Guelph/WestEndFull2';

export default function WestEndFull2Template () {
    const { fetchSchedulesGuelph, fetchJumbotronGuelph } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesGuelph();
                fetchJumbotronGuelph();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return (
        <>
            <HeaderWestEnd />
            <WestEndFull2 />
            <JumbotronWestEnd />
        </>
    )
}