import React, { useEffect, useContext } from 'react';
import HeaderVictoria from '../../components/Header/Guelph/HeaderVictoria';
import VictoriaFull from '../../components/Schedule/Guelph/VictoriaFull';
import JumbotronVictoria from '../../components/Jumbotron/Guelph/JumbotronVictoria';
import { ScheduleContext } from '../../context/ScheduleContext';

export default function VictoriaFullTemplate () {
    const { fetchSchedulesGuelph, fetchJumbotronGuelph } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesGuelph();
                fetchJumbotronGuelph();
            }
        }, 60000)

        return () => clearInterval(interval);
    });


    return (
        <>
        <HeaderVictoria />
        <VictoriaFull />
        <JumbotronVictoria />
        </>
    )
}