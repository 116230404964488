import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import RoomSchedule from './RoomSchedule';
import AdsElora from '../../Ads/Wellington/AdsElora';

export default function RoomListElora() {
  const { schedulesWellington } = useContext(ScheduleContext);
  const [bangquetHallSchedules, setBanquetHallSchedules] = useState([]);

  useEffect(() => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    const filterSchedules = (venueName) => {
      return schedulesWellington.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    let banquetHall = ensureTwoEntries(filterSchedules('Banquet Hall'));

    setBanquetHallSchedules(banquetHall);

  }, [schedulesWellington]);

  function ensureTwoEntries(schedules) {
  
    // Sort schedules by Date and StartTime
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });
  
    // Limit to the next 3 events
    const limitedSchedules = schedules.slice(0, 4);
  
    // Add placeholders if less than 3 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 4) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-1">
      <div className="row">
        <div className="col d-flex flex-column mt-1">
          <div className="flex-fill">
            <RoomSchedule venueName="Banquet Hall" schedules={bangquetHallSchedules} />
          </div>
        </div>
        <div className="col d-flex flex-column">
          <div className="flex-fill d-flex justify-content-center">
            <AdsElora />
          </div>
        </div>
      </div>
    </div>
  );
}
