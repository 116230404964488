import React, { useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JS is included

const AdsWellington = () => {
    const { adUrlsWellington } = useContext(ScheduleContext);

    return (
        <div id="adsCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="30000">
            {adUrlsWellington.length > 0 ? (
                <div className="carousel-inner">
                    {adUrlsWellington.map((url, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <img src={url} className="img-fluid" style={{ height: '440px', width: '500px' }} alt={`Ad ${index + 1}`} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="ad-placeholder h-100" style={{ backgroundColor: 'transparent', height: '100%' }}></div>
            )}
        </div>
    );
};

export default AdsWellington;
