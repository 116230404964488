import React from 'react';
import ScheduleListElora from '../../components/Schedule/Wellington/ScheduleListElora';
import RoomListElora from '../../components/Room/Wellington/RoomListElora';
import HeaderElora from '../../components/Header/Wellington/HeaderElora';
import { ScheduleContext } from '../../context/ScheduleContext';
import { useEffect, useContext } from 'react';

export default function EloraList() {
    const { fetchSchedulesWellington, fetchAdsElora } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesWellington();
                fetchAdsElora();
            }
        }, 60000)

        return () => clearInterval(interval);
    });


    return (
        <>
            <HeaderElora />
            <ScheduleListElora />
            <RoomListElora />
        </>
    )
}