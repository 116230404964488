import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import Arena from '../../components/Schedule/WestHants/Arena';

export default function WestHantsArena () {
    const { fetchSchedulesWestHants, fetchAdsWestHants } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 1 && currentHour < 6)) {
                fetchSchedulesWestHants();
                fetchAdsWestHants();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return (
        <Arena />
    )
}