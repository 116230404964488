import React, { useEffect, useContext } from 'react';
import HeaderExhibition from '../../components/Header/Guelph/HeaderExhibition';
import ExhibitionFull from '../../components/Schedule/Guelph/ExhibitionFull';
import JumbotronExhibition from '../../components/Jumbotron/Guelph/JumbotronExhibition';
import { ScheduleContext } from '../../context/ScheduleContext';

export default function ExhibitionFullTemplate () {
    const { fetchSchedulesGuelph, fetchJumbotronGuelph } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesGuelph();
                fetchJumbotronGuelph();
            }
        }, 60000)

        return () => clearInterval(interval);
    });
    
    return (
        <>
        <HeaderExhibition />
        <ExhibitionFull />
        <JumbotronExhibition />
        </>
    )
}