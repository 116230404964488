import React, { useState, useEffect } from 'react';
import '../Header.css';

export default function HeaderIPSCDate() {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId); 
    }, []);

    const formattedDate = currentTime.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark bg-gradient py-3 header-ipsc">
            <div className="container-fluid d-flex justify-content-end header-ipsc-date">
                <span className="navbar-text h1 kanit-regular text-white me-2">
                    {formattedDate} | {formattedTime}
                </span>
            </div>
        </nav>
    );
}
