import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderMCKTitle from '../../components/Header/Whitby/HeaderMCKTitle';
import MCKArena2Full from '../../components/Schedule/Whitby/MCKArena2Full';

export default function MCKArena2() {
    const { fetchSchedulesWhitby, fetchJumbotronWhitby } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesWhitby();
                fetchJumbotronWhitby();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return (
        <>
            <HeaderMCKTitle />
            <MCKArena2Full />
        </>
    )
}