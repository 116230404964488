import React from 'react';
import Header from '../components/Header/Header';
import ScheduleFull from '../components/Schedule/ScheduleFull';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import { ScheduleContext } from '../context/ScheduleContext';
import { useEffect, useContext } from 'react';

export default function EssexScheduleFull() {
    const { fetchSchedules, fetchJumbotron } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedules();
                fetchJumbotron();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return(
        <>
            <Header />
            <ScheduleFull />
            <Jumbotron />
        </>
    );
}