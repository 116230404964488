import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import AdsWellington from '../../Ads/Wellington/AdsWellington';
import RoomScheduleWellington from './RoomScheduleWellington';

export default function RoomListWellington() {
  const { schedulesWellington } = useContext(ScheduleContext);
  const [fullHallSchedules, setFullHallSchedules] = useState([]);
  const [gordBrownSchedules, setGrodBrownSchedules] = useState([]);
  const [unityHallSchedules, setUnityHallSchedules] = useState([]);
  const [boardroomASchedules, setBoardroomASchedules] = useState([]);
  const [boardroomBSchedules, setBoardroomBSchedules] = useState([]);
  const [mezzanineSchedules, setMezzanineSchedules] = useState([]);

  useEffect(() => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    const filterSchedules = (venueName) => {
      return schedulesWellington.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    let fullHallSchedules = ensureNEntries(filterSchedules('Full Hall'));
    let gordBrown = ensureNEntries(filterSchedules('Gord Brown Hall'));
    let unityHall = ensureNEntries(filterSchedules('Unity Hall'));
    let boardroomA = ensureNEntries(filterSchedules('Boardroom A'));
    let boardroomB = ensureNEntries(filterSchedules('Boardroom B'));
    let mezzanine = ensureNEntries(filterSchedules('Mezzanine'));

    setFullHallSchedules(fullHallSchedules);
    setGrodBrownSchedules(gordBrown);
    setUnityHallSchedules(unityHall);
    setBoardroomASchedules(boardroomA);
    setBoardroomBSchedules(boardroomB);
    setMezzanineSchedules(mezzanine);

  }, [schedulesWellington]);

  function ensureNEntries(schedules) {
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`).toLocaleString('en-CA', { timeZone: 'America/Toronto' });
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`).toLocaleString('en-CA', { timeZone: 'America/Toronto' });
      return new Date(dateA) - new Date(dateB);
    });
    
    // Limit to the next 5 events
    const limitedSchedules = schedules.slice(0, 2);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 2) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col d-flex flex-column gap-1">
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Full Hall" schedules={fullHallSchedules} />
          </div>
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Gord Brown Hall" schedules={gordBrownSchedules} />
          </div>
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Unity Hall" schedules={unityHallSchedules} />
          </div>
        </div>
        <div className="col d-flex flex-column">
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Boardroom A" schedules={boardroomASchedules} />
          </div>
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Boardroom B" schedules={boardroomBSchedules} />
          </div>
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Mezzanine" schedules={mezzanineSchedules} />
          </div>
        </div>
        <div className="col d-flex flex-column">
          <div className='flex-fill d-flex justify-content-center'>
            <AdsWellington />
          </div>
        </div>
      </div>
    </div>
  );
}
