import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderIPSCDate from '../../components/Header/Whitby/HeaderIPSCDate';
import IPSCArena3And6Full from '../../components/Schedule/Whitby/IPSCArena3And6Full';


export default function IPSCArena3And6() {
    const { fetchSchedulesWhitby, fetchJumbotronWhitby } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesWhitby();
                fetchJumbotronWhitby();
            }
        }, 60000)

        return () => clearInterval(interval);
    });
    
    return (
        <>
            <HeaderIPSCDate />
            <IPSCArena3And6Full />
        </>
    )
}