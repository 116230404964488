import '../Header.css';

export default function HeaderIPSCLogo() {
    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark bg-gradient py-3 header-ipsc">
            <div className="container-fluid">
                <div className="navbar-brand">
                    <img src="/logo_whitby.jpg" 
                         alt="Logo" 
                         width="150" 
                         height="50" 
                         className="d-inline-block align-text-top rounded img-thumbnail" />
                </div>
            </div>
        </nav>
    );
}
