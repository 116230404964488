import React from 'react';

function formatTime(time24) {
  if (!time24) return ''; // Return an empty string if time24 is undefined or null
  const [hour, minute] = time24.split(':');
  let hour12 = parseInt(hour, 10);
  // const period = hour12 >= 12 ? 'PM' : 'AM';
  hour12 = hour12 % 12 || 12; // Convert hour to 12-hour format, 0 becomes 12
  return `${hour12}:${minute}`;
}

function combineTimes(startTime, endTime) {
  const formattedStart = formatTime(startTime);
  const formattedEnd = formatTime(endTime);
  return formattedStart ? `${formattedStart} - ${formattedEnd}` : '';
}

export default function RoomScheduleWellington({ venueName, schedules }) {
  return (
    <div className="card">
      <div className="card-header bg-warning text-white h2 kanit-regular">
        {venueName}
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered text-center" >
          <tbody className='h4'>
            {schedules.map((schedule, index) => (
              <tr key={index} style={{ height: '40px', lineHeight: '20px', padding: '0', margin: '0', overflow: 'hidden', width: '18%',
                maxWidth: '18%',
                minWidth: '18%', }}>
                <td 
                  style={{ 
                    whiteSpace: 'nowrap',    // Prevents wrapping
                    height: '40px !important',          // Enforce smaller row height
                    lineHeight: '40px !important',      // Single line spacing matching height
                    padding: '0 !important',            // Remove padding
                    margin: '0 !important',  
                    width: '24%'           // Remove margin
                  }}
                >
                  {combineTimes(schedule.StartTime, schedule.EndTime)}
                </td>
                <td 
                  style={{ 
                    height: '40px !important',          // Enforce smaller row height
                    lineHeight: '40px !important',      // Single line spacing matching height
                    padding: '0 !important',            // Remove padding
                    margin: '0 !important',             // Remove margin
                    whiteSpace: 'nowrap',    // Prevents wrapping
                    minHeight: '40px'
                  }}
                >
                  {schedule.Team || ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
