import React, { useEffect, useContext } from 'react';
import HeaderWestEnd from '../../components/Header/Guelph/HeaderWestEnd';
import WestEndFull1 from '../../components/Schedule/Guelph/WestEndFull1';
import JumbotronWestEnd from '../../components/Jumbotron/Guelph/JumbotronWestEnd';
import { ScheduleContext } from '../../context/ScheduleContext';

export default function WestEndFull1Template () {
    const { fetchSchedulesGuelph, fetchJumbotronGuelph } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesGuelph();
                fetchJumbotronGuelph();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return (
        <>
        <HeaderWestEnd />
        <WestEndFull1 />
        <JumbotronWestEnd />
        </>
    )
}