import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueSchedule from './VenueSchedule';
import '../Schedule.css';
import Ads from '../../Ads/WestHants/Ads';

export default function Field () {
    const { schedulesWestHants, teamsWestHants } = useContext(ScheduleContext); // Use context to get schedules and teams
    const [fieldSchedules, setFieldSchedules] = useState([]);
    const [teamMap, setTeamMap] = useState({});

    useEffect(() => {
      // Get today's date in EST
      const now = new Date();
      const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Halifax' });

      // Create a map of team codes to team names
      const teamMapping = teamsWestHants.reduce((map, team) => {
          map[team.Code] = team.Name;
          return map;
      }, {});
      setTeamMap(teamMapping);

      // Filter schedules for each venue
      const filterSchedules = (venueName) => {
        return schedulesWestHants.filter(s => {
          if (s.Venue === venueName && s.Date === today) {
            // Combine the schedule date and EndTime to create a complete Date object
            const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
            const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

            return now <= thirtyMinutesAfterEnd;
          }
          return false;
        });
      };

      // Filter schedules for today
      const field = ensureNEntries(filterSchedules('West Hants Field 1'));
      setFieldSchedules(field);
      
    }, [schedulesWestHants, teamsWestHants]); // Rerender when schedules or teams context changes

    function ensureNEntries(schedules) {
        // Sort schedules by Date and StartTime
        schedules.sort((a, b) => {
            const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
            const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
            return dateA - dateB; // Compare as Date objects
        });
      
        // Limit to the next 8 events
        const limitedSchedules = schedules.slice(0, 8);
      
        // Add placeholders if less than 8 events are found
        const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
        while (limitedSchedules.length < 8) {
            limitedSchedules.push(placeholder);
        }
      
        return limitedSchedules;
    }

    return (
        <div className="container-fluid d-flex flex-column mt-5">
            <div className="row flex-grow-1">
                <div className="col-md-8">
                    <Ads />
                </div>
                <div className="col-md-4 d-flex align-items-start justify-content-end">
                    <VenueSchedule venueName="Field" schedules={fieldSchedules} teamMap={teamMap} />
                </div>
            </div>
        </div>
    );
}