import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderIPSCDate from '../../components/Header/Whitby/HeaderIPSCDate';
import MCKArena3Full from '../../components/Schedule/Whitby/MCKArena3Full';

export default function MCKArena3() {
    const { fetchSchedulesWhitby, fetchJumbotronWhitby } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesWhitby();
                fetchJumbotronWhitby();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return (
        <>
            <HeaderIPSCDate />
            <MCKArena3Full />
        </>
    )
}