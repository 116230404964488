import React from 'react';
import HeaderWellington from '../../components/Header/Wellington/HeaderWellington';
import ScheduleListWellington from '../../components/Schedule/Wellington/ScheduleListWellington';
import RoomListWellington from '../../components/Room/Wellington/RoomListWellington';
import { ScheduleContext } from '../../context/ScheduleContext';
import { useEffect, useContext } from 'react';

export default function WellingtonList() {
    const { fetchSchedulesWellington, fetchAdsWellington } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 6)) {
                fetchSchedulesWellington();
                fetchAdsWellington();
            }
        }, 60000)

        return () => clearInterval(interval);
    });

    return (
        <>
            <HeaderWellington />
            <ScheduleListWellington />
            <RoomListWellington />
        </>
    )
}